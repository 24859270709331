import React, { Component }  from 'react';
import '../components/Header.css';
const SliderNews = () => {

    return (
        <div className="p-5 mb-4 bg-light rounded-3 sliderNews">

        </div>
    );
}

export default SliderNews;


