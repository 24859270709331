import React, { Component }  from 'react';
import {NavLink} from "react-router-dom";

export const CamBloccoCards = () => {
    return (
        <div className="row ">
            <div className={'col-xs-12 col-md-12 col-lg-12 mb-2'}>
                <h2 className={'text-center fw-bolder'}>CAM in vigore, in via di definizione,<br/> in programma</h2>
                <p className={'text-center'}>I Criteri Ambientali Minimi esistono per ogni settore merceologico. Eccone alcuni, tra quelli che suscitano maggiore interesse tra le aziende:
                </p>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4 ">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM edilizia</h3>
                        <p className={'tecnoGrenn text-center'}>*in vigore</p>

                        <p className="card-text text-center">Per l’affidamento di appalti di nuova costruzione,
                            ristrutturazione, manutenzione, riqualificazione energetica di edifici e la gestione dei
                            cantieri.</p>
                        <p className={'text-center'}>
                            <NavLink to={'/news/cam-edilizia--le-novit%C3%A0-previste-dal-decreto-ministeriale-5-agosto-2024'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM lavanolo</h3>
                        <p className={'tecnoGrenn text-center'}>*in vigore</p>

                        <p className="card-text text-center">Per l’affidamento del servizio di ricondizionamento,
                            logistica e noleggio di dispositivi tessili, materasseria, indumenti ad alta visibilità
                            nonché dei dispositivi medici sterili.</p>
                        <p className={'text-center'}>
                            <NavLink to={'/news/cam-criteri-ambientali-minimi-lavanolo-come-accedere-agli-appalti-pubblici'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM strade</h3>
                        <p className={'tecnoGrenn text-center'}>*in via di definizione</p>

                        <p className="card-text text-center">Per l’affidamento di servizi per la progettazione e per i
                            lavori inerenti a costruzione, manutenzione e adeguamento funzionale infrastrutture
                            stradali.
                        </p>
                        <p className={'text-center'}>
                             <NavLink to={'/news/cam-strade-2024--tutte-le-novit%C3%A0-in-vigore-da-dicembre'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM verde pubblico</h3>
                        <p className={'tecnoGrenn text-center'}>*in vigore</p>

                        <p className="card-text text-center">Per l’affidamento di servizi di progettazione di nuove aree
                            verdi o riqualificazione di aree già esistenti; inclusi servizi di gestione e manutenzione
                            del verde pubblico e per la fornitura di prodotti.
                        </p>
                        <p className={'text-center'}>
                            <NavLink to={'/news/cam-verde-pubblico-cosa-sono-i-nuovi-criteri-ambientali-minimi'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM eventi culturali</h3>
                        <p className={'tecnoGrenn text-center'}>*in vigore</p>

                        <p className="card-text text-center">Per l’affidamento di servizi di organizzazione e gestione
                            degli eventi culturali, incluse mostre ed esposizioni. Considera tutte le fasi dell’evento:
                            dall’organizzazione alle attività post evento.
                        </p>
                        <p className={'text-center'}>
                            <NavLink to={'/news/cultura-sostenibile-le-linee-guida-per-i-cam-eventi-culturali'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="card personalcardCam" data-aos='fade-down'>
                    <div className="card-body">
                        <h3 className="card-title titleH3Cam fw-bold text-uppercase text-center">CAM ristorazione
                            collettiva</h3>
                        <p className={'tecnoGrenn text-center'}>*in vigore</p>

                        <p className="card-text text-center">Per l’affidamento del servizio di ristorazione collettiva e
                            la fornitura di derrate alimentari; con criteri ambientali dedicati ai servizi per: scuole,
                            uffici, caserme, ospedali e altre strutture assistenziali.
                        </p>
                        <p className={'text-center'}>
                            <NavLink to={'/news/cam-ristorazione-collettiva-come-diventare-fornitore-della-pa'} className={'tecnoGrennNews'}>Leggi di più</NavLink>
                        </p>

                    </div>
                </div>
            </div>

        </div>

    );
}
