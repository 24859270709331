import React, { Component }  from 'react';
import Jumbatron from '../components/Jumbatron';
import '../components/Header.css'
import Buttons from "../components/Buttons";
export const Nontfound = () =>{
    return (
        <section>
            <Jumbatron></Jumbatron>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-xs-12 col-md-12 col-lg-12'}>
                        <h2 className={'fw-bold text-center'}>OPS</h2>
                        <h2 className={'fw-bold text-center'}>Sembra che qualcosa sia andato storto</h2>
                        <p className={'text-center'}>
                            Questa pagina non è disponibile.<br/>
                            Il link potrebbe essere non funzionante o la pagina potrebbe essere stata <br/>
                            rimossa. <br/>
                            Verifica che il link che stai cercando di aprire sia corretto.<br/><br/>
                            <Buttons title={'INDIETRO'} link={'/'} ></Buttons>
                        </p>

                    </div>
                </div>
            </div>

        </section>
    )
}
