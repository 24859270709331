import React, { Component }  from 'react';
import { Route, Switch } from "react-router-dom";
import { Header } from "./components/Header";
import { Slider } from "./components/Slider";
import { PrimoBlocco } from "./components/PrimoBlocco";
import { SecondoBlocco } from "./components/SecondoBlocco";
import "./App.css";
import "animate.css/animate.min.css";
import { TerzoBlocco } from "./components/TerzoBlocco";
import { Footer } from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Nontfound } from "./pages/Nontfound";
import { SliderPagina2 } from "./components/SliderPagina2";
import { CamBlocco } from "./components/CamBlocco";
import { CamBloccoCards } from "./components/CamBloccoCards";
import { CamBloccoDue } from "./components/CamBloccoDue";
import { PreForm } from "./components/PreForm";
import { Blog } from "./apiblog/Blog";
import SliderBlog from "./components/SliderBlog";
import { News } from "./apiblog/News";
import SliderNews from "./components/SliderNews";
import { Forms } from "./components/Forms";
import { ImCircleUp } from "react-icons/im";
import ScrollButton from "./components/ScrollTop";
import { Grazie } from "./pages/grazie";
import { SecondoBloccoPlus } from "./components/SecondoBloccoPlus";
import { Recensione } from './components/Recensione';

function App() {
  AOS.init();

  return (
    <div>
      <Header></Header>
      <main>
        <Switch>
          <Route exact path={"/"}>
            <Slider></Slider>
            <section className="container">
              <PrimoBlocco weight="fw-bolder"></PrimoBlocco>
            </section>
            <div className={" martop-70 m-top-15"}>
              <section className="container">
                <SecondoBlocco></SecondoBlocco>
              </section>
            </div>
            <div className={"sfondoPulsanti martop-70 m-top-15"}>
              <section className="container">
                <SecondoBloccoPlus></SecondoBloccoPlus>
              </section>
            </div>
            <section className="container">
              <TerzoBlocco></TerzoBlocco>
            </section>
            <Forms title={"Scrivici"}></Forms>
          </Route>
          <Route exact path={"/cam"}>
            <SliderPagina2></SliderPagina2>
            <section className={"container"}>
              <CamBlocco></CamBlocco>
            </section>
            <div className={"sfondoPulsanti martop-70"}>
              <section className={"container"}>
                <CamBloccoCards></CamBloccoCards>
              </section>
            </div>
            <section className={"container"}>
              <CamBloccoDue></CamBloccoDue>
            </section>
            <section className={"container"}>
              <PreForm></PreForm>
            </section>
            <Forms
              title={"Ottieni con noi le certificazioni ambientali"}
            ></Forms>
          </Route>
          <Route exact path={"/news"}>
            <SliderBlog></SliderBlog>
            <section className="container">
                <Recensione></Recensione>
              </section>
            <Blog></Blog>
          </Route>
          <Route exact path={"/news/:blog"}>
            <SliderNews></SliderNews>
            <News></News>
          </Route>
          <Route exact path={"/grazie"}>
            <Grazie></Grazie>
          </Route>
          <Route path={"*"} exact>
            <Nontfound></Nontfound>
          </Route>
        </Switch>
      </main>
      <ScrollButton></ScrollButton>
      <footer className="container-fluid footer">
        <Footer></Footer>
      </footer>
    </div>
  );
}

export default App;
