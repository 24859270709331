import React, { Component }  from 'react';
import Buttons from "../components/Buttons";
import '../components/Header.css'
import GrazieJumb from "../components/GrazieJumb";
export const Grazie = () =>{
    return (
        <section>
            <GrazieJumb></GrazieJumb>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-xs-12 col-md-12 col-lg-12'}>
                        <h2 className={'fw-bold text-center'}>La richiesta è andata a buon fine</h2>
                        <h2 className={'fw-bold text-center'}>Ti contatteremo il prima possibile</h2>
                        <p className={'text-center'} style={{fontSize:'22px'}}>
                            Se hai una richiesta urgente chiamaci al<br/></p>
                            <p className={'text-center'} style={{fontSize:'22px'}}>numero verde <a href={'tel:800.128.047'}> 800.128.047</a>

                            <br/><br/>

                        </p>
                        <p className={'text-center'} style={{fontSize:'22px'}}>
                         <Buttons title={'INDIETRO'} link={'/'} ></Buttons>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}