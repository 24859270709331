import React, { Component }  from 'react';
import {NavLink} from "react-router-dom";
export const ColonnaTre = (props) =>{

    return (
        <div className={'col-xs-12 col-md-4 col-lg-4 mb-5'}>
            <img src={props.image}  className={'img-fluid mb-3'} alt={props.title}/>
            <h4 className={'fw-bold'}><NavLink to={'/news/'+props.url} id={'blogstitlehref'} >{props.title}</NavLink></h4>
            <p>{props.sottotitolo}</p>
            <NavLink to={'/news/'+props.url} className={'tecnoGrennNews'}>Leggi di più</NavLink>
        </div>
    )
}