import React, { Component }  from 'react';
const Jumbatron = () => {
    return (
        <div className="p-5 mb-4 bg-light rounded-3 notfound">

        </div>
    );
}

export default Jumbatron;


