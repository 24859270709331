import { HashLink } from "react-router-hash-link";
import React, { Component }  from 'react';
import Anna from '../assets/anna.png';
import Fede from '../assets/Fede.png';
import Roberta from '../assets/Roberta.png';
import Nicola from '../assets/Nicola.png';
import { Titolo } from "./Titolo";
export const Recensione = () => {
  return (
    <div className="row mb-5">
      <div className="col-xs-12 col-lg-12 ">
        <h1
          className="display-5 fw-bold  mb-3 text-center"
          style={{
            minHeight: 5,
            fontSize: "2rem",
          }}
        >
          Gli approfondimenti della nostra redazione
        </h1>
        <p className="text-center mb-5">
          Le autrici e gli autori della nostra redazione hanno una mission ben
          precisa: aiutare le aziende a comprendere tutte le opportunità che la
          sostenibilità riserva a chi sceglie di adottare un modello di business
          basato sulla responsabilità ambientale.
        </p>
      </div>
      <div className="col-xs-12 col-md-3">
        <h5 className=" text-center fw-bold mb-5">Federica Pacilio</h5>
        <p className="text-center">  <img src={Fede} className="img-fluid rounded" alt="Certificazione in 5 step"/></p>
      </div>
      <div className="col-xs-12 col-md-3 ">
        <h5 className=" text-center fw-bold  mb-5">Roberta De Simone</h5>
        <p className="text-center">  <img src={Roberta} className="img-fluid rounded" alt="Certificazione in 5 step"/></p>
      </div>
      <div className="col-xs-12 col-md-3">
        <h5 className=" text-center fw-bold  mb-5"> Anna Tammaro</h5>
        <p className="text-center">  <img src={Anna} className="img-fluid rounded" alt="Certificazione in 5 step"/></p>
      </div>
      <div className="col-xs-12 col-md-3">
        <h5 className="  text-center fw-bold  mb-5">Nicola Gallo</h5>
        <p className="text-center">  <img src={Nicola} className="img-fluid rounded" alt="Certificazione in 5 step"/></p>
      </div>
     <div className="mt-5">
     <Titolo title={'I nostri contenuti'}></Titolo>
     </div>
    </div>
   
    
  );
};
